import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import BlogRoll from "../components/BlogRoll";

const bgBlueGreen = `url(/img/bg-green-blue-gradient.png)`;

// eslint-disable-next-line
export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  date,
  author,
  authorDescription,
  authorProfilePic,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="pt-24">
      {helmet || ""}
      <main className="pt-8 pb-16 lg:pt-12 lg:pb-24 bg-white">

        <div className=" mx-auto max-w-screen-xl ">
            <article className="mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-blue ">
                <header className="mb-4 lg:mb-6 not-format">
                    <address className="flex items-center mb-6 not-italic">
                        <div className="inline-flex items-center mr-3 text-sm text-gray-900 ">
                            <img className="mr-4 w-16 h-16 rounded-full" src={authorProfilePic}  alt={author}/>

                            <div>
                                <div rel="author" className="text-xl  text-gray-900 ">{author}</div>
                                <p className="text-base font-light text-gray-500 dark:text-gray-400">{authorDescription}</p>
                                <p className="text-base font-light text-gray-500 dark:text-gray-400"><time pubdate datetime={date} title={date}>{date}</time></p>
                            </div>
                        </div>
                    </address>
                    <h1 className="mb-4 text-4xl font-medium leading-tight text-gray-900 lg:mb-6 lg:text-5xl ">  {title}</h1>
                </header>

                  <p>{description}</p>
                  <PostContent content={content} />
            </article>
            <div className="max-w-2xl mx-auto">
            {tags && tags.length ? (
                    <div style={{ marginTop: `4rem` }}>
                      <h4 className="mb-3">Tags</h4>
                      <ul className="flex justify-start m-0">
                        {tags.map((tag) => (
                          <li key={tag + `tag`} className="mr-4 mb-4">
                            <svg className="inline mr-2 fill-gray-400" xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M181.3 32.4c17.4 2.9 29.2 19.4 26.3 36.8L197.8 128h95.1l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3s29.2 19.4 26.3 36.8L357.8 128H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H347.1L325.8 320H384c17.7 0 32 14.3 32 32s-14.3 32-32 32H315.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8l9.8-58.7H155.1l-11.5 69.3c-2.9 17.4-19.4 29.2-36.8 26.3s-29.2-19.4-26.3-36.8L90.2 384H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l21.3-128H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h68.9l11.5-69.3c2.9-17.4 19.4-29.2 36.8-26.3zM187.1 192L165.8 320h95.1l21.3-128H187.1z"/></svg>
                            <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : null}
            </div>
        </div>
      </main>

      <aside aria-label="Related articles" className="py-8 lg:py-12 bg-gray-50">
        <div className="px-4 mx-auto max-w-screen-xl">
            <h2 className="mb-8 text-2xl  text-gray-900 ">Related articles</h2>
            <BlogRoll postCount={3} />
        </div>
      </aside>

      <section className="bg-cover" style={{backgroundImage: bgBlueGreen}}>
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div className="mx-auto max-w-screen-md sm:text-center">
                <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl dark:text-white">Sign up for our newsletter</h2>
                <p className="mx-auto mb-8 max-w-2xl font-light text-white md:mb-12 sm:text-xl">Stay up to date with the roadmap progress, announcements and exclusive discounts feel free to sign up with your email.</p>
                <form action="#">
                    <div className="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
                        <div className="relative w-full">
                            <div for="email" className="hidden mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Email address</div>
                            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                            </div>
                            <input className="block p-3 pl-10 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 sm:rounded-none sm:rounded-l-lg focus:ring-primary-500 focus:border-primary-500 " placeholder="Enter your email" type="email" id="email" required=""/>
                        </div>
                        <div>
                            <button type="submit" className="py-3 px-5 w-full text-sm font-medium text-center text-white rounded-lg border cursor-pointer bg-orange-500 border-orange-500 sm:rounded-none sm:rounded-r-lg hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 ">Subscribe</button>
                        </div>
                    </div>
                    <div className="mx-auto max-w-screen-sm text-sm text-left text-white newsletter-form-footer ">We care about the protection of your data. <a href="/privacy-policy" className="font-medium text-primary-600 dark:text-primary-500 hover:underline">Read our Privacy Policy</a>.</div>
                </form>
            </div>
        </div>
      </section>


    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const { allMarkdownRemark: authors } = data;

  const author = post.frontmatter.author;
  // Filter allMarkdownRemark on the client side based on the author value
  const filteredAuthors = authors.edges.filter(
    (edge) => edge.node.frontmatter.title === author
  );


  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        date={post.frontmatter.date}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        author={post.frontmatter.author}
        authorDescription={filteredAuthors[0].node.frontmatter.description}
        authorProfilePic={filteredAuthors[0].node.frontmatter.featuredimage.publicURL}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(
      id: { eq: $id }
    ) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        author
        description
        tags
      }
    },
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "authors-page"}}}) {
      edges {
        node {
          frontmatter {
            description
            title
            featuredimage {
              publicURL
              id
            }
          }
          id
        }
      }
    }
  }
`;
